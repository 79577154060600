import Vue from 'vue'
import './plugins/vuetify'
import router from './router'
import BootstrapVue from "bootstrap-vue"
import App from './App'
import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';
import store from './store'
import languages from './i18n'
import VeeValidate from 'vee-validate';
import VueOrgTree from 'vue-org-tree'
import 'vue-orgchart/dist/style.min.css'
Vue.use(VueOrgTree);
import VueClipboard from 'vue-clipboard2'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCountryRegionSelect from 'vue-country-region-select'

// import ProgressBar from 'vuejs-progress-bar'
// Vue.use(ProgressBar)


//infotrend
import { baseURL } from './config';

Vue.use(vueCountryRegionSelect)
Vue.use(VueAxios, axios)
import { createProvider } from './vue-apollo'
Vue.prototype.moment = moment;
Vue.use(VueClipboard)
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors'
})
import moment from 'moment'
import VueI18n from "vue-i18n";
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('D. MMM YYYY HH:mm:ss')
    }
});
Vue.filter('formatID', function (value) {
    if (value) {
        return +new Date(String(value));
    }
});
Vue.filter('formatDateShort', function (value) {
    if (value) {
        return moment(String(value)).format('D. MMM YYYY')
    }
});
Vue.filter('formatHtl', function (value) {
    if (value) {
        return (value / 1e8).toFixed(2);
    }

});
Vue.filter('formatTicket', function (value) {
    if (value) {
        switch (value.code) {
            case "tw23":
                return value.quantity + "x Winter 2023 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "ts23":
                return value.quantity + "x Summer 2023 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "ta23":
                return value.quantity + "x Autumn 2023 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "tw24":
                return value.quantity + "x Winter 2024 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "ts24":
                return value.quantity + "x Summer 2024 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "ta24":
                return value.quantity + "x Autumn 2024 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "ts25":
                return value.quantity + "x Spring 2025 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            case "tm25":
                return value.quantity + "x Summer 2025 seminar ticket" + ((value.quantity > 1) ? "s" : "");
            default:
                return value.quantity + "x " + value.code + " seminar ticket(?)";

        }
    }
    return value
}
);
Vue.filter('formatEur', function (value) {
    return (value / 1e4).toFixed(2);
});
Vue.filter('timediff', function (val) {
    return moment.utc(val).format('D [days] HH[h] mm[m] ss[s]')
})
Vue.use(VeeValidate);
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);
const messages = Object.assign(languages)
let locale = localStorage.getItem('lang') || 'en'
var i18n = new VueI18n({
    locale: locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: messages
});
Vue.filter('leg', function (val) {
    switch (val) {
        case 1:
            return "left";
        case 2:
            return "right";
        default:
            return "unknown"
    }
})
Vue.filter('pool', function (val) {
    switch (val) {
        case 0:
            return "No pool";
        case 1:
            return "Pool";
        case 2:
            return "Pool plus";
        case 3:
            return "Founder";
        default:
            return "No pool";
    }
})
Vue.filter('rank', function (val) {
    console.log(val);
    const rankName = [
        "Menjaj"
    ];
    return rankName[0];
})

//INFOTREND
Vue.mixin({
    data: function () {
        return {
            get baseURL() {
                return baseURL;
            }
        }
    }
});

new Vue({
    el: '#app',
    router,
    apolloProvider: createProvider(),
    i18n,
    store,
    template: '<App/>',
    components: { App },
});
